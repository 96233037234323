.container {
  // padding: 0 2rem;
  // flex: 1;
}
.list {
  padding: 2rem 0;
  &.second_list {
    border-top: 0.1rem solid #e3e3e3;
  }
}
.list_title {
  display: flex;
  justify-content: space-between;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2rem;
  padding: 0 1.5rem 1rem;
}
.item {
  padding: 1rem 1.5rem;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 130%;
  color: rgba(66, 0, 255, 1);
  &.active {
    background-color: rgba(66, 0, 255, 1);
    color: #fff;
    border-radius: 1rem;
  }
}
.next_btn {
  position: sticky;
  bottom: 3rem;
  padding-top: 3rem;
  button,
  a {
    width: 100%;
    height: 8rem;
    font-size: 2.4rem;
  }
}

.car_number_container {
  .car_number_input {
    text-align: center;
    font-size: 4rem;
    font-family: 'Circe';
    font-weight: 400;
    &::placeholder {
      color: #cbcbcb;
    }
  }
}
.add_car_btn {
  position: absolute;
  bottom: 0;
  left: 2rem;
  width: calc(100% - 4rem);
  button,
  a {
    width: 100%;
    height: 8rem;
    font-size: 2.4rem;
  }
}

.auto_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  .inner_container {
    height: fit-content;
    width: 100%;
    .title {
      font-size: 2rem;
      line-height: 130%;
      color: #000;
      font-weight: bold;
      text-align: center;
    }
  }
  .car_container {
    padding: 2rem;
    border-radius: 1.5rem;
    border: 0.2rem solid #4200ff;
    max-width: 33rem;
    width: 100%;
    box-sizing: border-box;
    margin: 3.5rem auto 0;
    .top {
      display: flex;
      justify-content: space-between;
    }
    .name {
      font-size: 2rem;
      line-height: 130%;
      color: #000;
      font-weight: bold;
    }
    .number {
      border-radius: 0.5rem;
      border: 0.1rem solid #000;
      text-align: center;
      width: 11rem;
      font-size: 1.2rem;
      line-height: 130%;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        position: relative;
        top: 0.1rem;
      }
    }
  }
  .btn {
    margin: 2rem auto 0;
    max-width: 33rem;
    button {
      height: 7rem;
      width: 100%;
      font-size: 1.8rem;
    }
  }
}
.title {
  font-weight: 700;
  font-size: 2rem;
  line-height: 110%;
  color: #000;
  margin-bottom: 3rem;
  text-align: center;
  position: relative;

  .title_icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 2.2rem;
    height: 2.2rem;
  }
}
