.content {
  padding: 0 0 4rem 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  transition: all 0.3s ease;
  overflow: auto;

  &.collapsed {
    padding: 4rem 0.9rem;
  }
}

.icon {
  svg {
    width: 1.8rem;
    height: 1.8rem;

    path {
      stroke: #000000;
      fill: #000000;
    }
  }
}

.selector_content {
  width: 100%;

  span {
    text-align: center;
    display: block;
    margin: 0 auto;
  }

  &.hide_content {
    height: 0;
    padding-bottom: 5rem;
  }
}
.selector {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 1.5rem 2rem;
  justify-content: space-between;
  color: #fff;
  background: #ff7a00;
  // border-radius: 1rem;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2rem;
  cursor: pointer;
  width: 100%;
  position: relative;
  overflow: hidden;
  transition: 1.5s ease-in-out;
  opacity: 1;
  height: 7rem;
  &.hide {
    opacity: 0;
    width: 0;
    height: 0;
    transition: 0.1s ease-in-out;
  }

  svg {
    width: 1rem;
    position: relative;
    top: -0.2rem;
  }
  .loader {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.4);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      margin: 0 auto;
      padding: 0;
    }
  }
}
.nav {
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  flex: 1;
  .nav_item {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2rem;
    color: #101010;
    padding: 1rem 2rem;
    // border-radius: 1rem;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    .icon {
      width: 2.4rem;
      height: 2.4rem;
    }
    svg {
      transition: all 0.3s ease;
      // opacity: 0;
    }
    .name {
      margin-left: 1.5rem;
    }

    &:hover {
      background: #fbfbfb;
    }
    &.active {
      background: #fbfbfb;
      color: #ff9900;
      svg {
        opacity: 1;
      }
    }
  }
  .nav_bottom {
    margin-top: auto;
  }
}

.item_text {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2rem;
  color: #101010;
  transition: 1.5s ease-in-out;
  opacity: 1;
  flex-shrink: 0;
  &.collapse_text {
    opacity: 0;
    transition: 0.1s ease-in-out;
  }
}

.item_icon {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  svg {
    width: 2rem;
    height: 2rem;
  }
}

.counter {
  background: #ff2b2b;
  border-radius: 1.5rem;
  margin-left: auto;
  padding: 0.1rem 1rem;
  color: #fff;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2rem;
}

.nav_last_info {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #aeaeae;
  padding-left: 3.8rem;
  margin-top: 2rem;
  transition: 1.5s ease-in-out;
  opacity: 1;
  flex-shrink: 0;
  &.collape_info {
    opacity: 0;
    transition: 0.1s ease-in-out;
  }
}
a.nav_last_info {
  display: block;
  margin-top: 1.5rem;
}
.opened_from {
  color: #ededed;
  font-size: 1.4rem;
  font-weight: 400;
}

@media (orientation: portrait), (max-width: 768px) {
  .icon {
    display: none;
  }
}
