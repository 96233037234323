.modal_container {
  max-width: 100rem;
}
.title {
  font-size: 2rem;
  line-height: 130%;
  color: #000;
  font-weight: bold;
}
.product_container {
  margin-top: 2rem;
  border-top: 0.6rem solid #eee;
}
.product,
.variety {
  display: flex;
  justify-content: space-between;
}
.buttons {
  display: flex;
  button {
    margin-left: 1rem;
  }
}

.product {
  margin-top: 1rem;
  padding: 1rem 1.5rem 1rem 0;
  .name {
    font-size: 2rem;
    line-height: 130%;
    color: #000;
    font-weight: bold;
  }
}

.variety {
  border: 0.5rem solid #f5f3ef;
  border-radius: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  padding: 1rem 1rem;
  .name {
    font-size: 1.8rem;
    line-height: 130%;
    color: #000;
  }
}

.check_btn {
  position: sticky;
  bottom: -3rem;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 3rem 0;
  button {
    width: 30rem;
    height: 6rem;
  }
}
