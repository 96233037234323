.title {
  font-weight: 600;
  font-size: 2.8rem;
  line-height: 3.4rem;
  color: #2e2e3a;
  // margin-bottom: 2rem;
}

.back {
  margin-right: 2.5rem;
  width: 1rem;
  height: 1.8rem;
  margin-left: 1rem;
  cursor: pointer;
  path {
    fill: #2e2e3a;
  }
}

.right {
  margin-left: auto;
  flex-shrink: 0;
}

@media (max-width: 768px) {
  .title {
    font-size: 1.8rem;
    line-height: 2rem;
  }
}
