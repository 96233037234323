.container {
  padding: 6rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner_content {
  max-width: 53rem;
}
.title {
  text-align: center;
  font-weight: 600;
  font-size: 2.8rem;
  line-height: 3.4rem;
  color: #70707c;
}
.subtitle {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  text-align: center;
  color: #70707c;
  margin-top: 2rem;
}

.button {
  cursor: pointer;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  background: #ff9900;
  border-radius: 10rem;
  padding: 1.5rem 3rem;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 130%;
  color: #fff;
}
