.more_button {
  font-size: initial;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  transform: translate(0, 0);
  cursor: pointer;
  position: unset;

  &.opened {
    position: relative;
    z-index: 1;
  }

  > img {
    width: 1.6rem;
  }

  .dropdown_menu {
    position: absolute;
    display: none;
    top: 6rem;
    right: 0;
    background: #ffffff;
    box-shadow: 0px 4px 40px #f0f0f0;
    border-radius: 10px;
    padding: 1rem 0;
    width: 24rem;
    max-height: 25rem;
    overflow: auto;

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #eaeaea;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #eaeaea;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #bebebe;
    }

    > div,
    a {
      display: flex;
      align-items: center;
      padding: 1rem 2.5rem;
      font-weight: normal;
      font-size: 1.5rem;
      color: #000000;
      transition: all 0.3s ease;
      &:hover {
        background: #ff7a00;
        color: #fff;
      }
    }

    &.opened {
      display: block;
    }
  }
}
.disabled {
  pointer-events: none;
}
