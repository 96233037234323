.nav {
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  flex: 1;
}
.item_main {
  padding: 1rem 2rem;
  border-radius: 1rem;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  cursor: pointer;

  .item_text {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 130%;
    color: #101010;
    transition: 1.5s ease-in-out;
    opacity: 1;
    flex-shrink: 0;
    flex: 1;
    &.collapse_text {
      opacity: 0;
      transition: 0.1s ease-in-out;
    }
  }

  .item_icon {
    width: auto;
    max-width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
  svg {
    transition: all 0.3s ease;
    // opacity: 0;
  }
  //   .name {
  //     margin-left: 1.5rem;
  //   }

  &:hover {
    background: #fbfbfb;
  }
  &.active {
    background: #fbfbfb;
    color: #ff9900;
    svg {
      opacity: 1;
    }
  }
}

.nav_item {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 130%;
  color: #909090;
  padding: 1rem 2rem;
  display: block;
  &.active {
    font-weight: 700;
    color: #0290ea;
  }
}

.counter {
  background: #ff2b2b;
  border-radius: 1.5rem;
  margin-left: auto;
  padding: 0.1rem 1rem;
  color: #fff;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2rem;
}

@media (orientation: portrait), (max-width: 768px) {
  .item_main {
    padding: 1.5rem 2rem;
    .item_text {
      font-size: 2rem;
    }
    .item_icon {
      max-width: 3rem;
      height: 3rem;
      svg {
        width: 3rem;
        height: 3rem;
      }
    }
  }
  .nav_item {
    font-size: 1.8rem;
    padding: 1.5rem 2rem;
  }
}
