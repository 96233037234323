.nav {
  display: flex;
  align-items: center;
  margin-left: 6.5%;

  .nav_item {
    color: #2e2e3a;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2rem;
    margin: 0 1.5rem;
    &.active {
      color: #0062ff;
    }
  }
}

.right_block {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.rate_block {
  width: 17rem;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .rate {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: #70707c;
    .black {
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.2rem;
      color: #000;
    }
  }
  .sub_rate {
    .stars {
      display: flex;
      svg {
        width: 1.2rem;
        height: 1.2rem;
        display: block;
        margin: 0 0.1rem;
      }
    }
    .procent {
      color: #24ca49;
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 1.4rem;
    }
  }
}
.profile_block {
  display: flex;
  align-items: center;
  margin-left: 4.5rem;

  .profile_nav_item {
    width: 4.6rem;
    height: 4.6rem;
    border-radius: 1rem;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    &:hover {
      background-color: #f8f9fb;
    }
    svg {
      width: auto;
      height: 2.2rem;
    }
  }

  .user {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
      background-color: #f8f9fb;
    }
    img {
      width: 4.2rem;
      height: 4.2rem;
      border-radius: 50%;
      object-fit: cover;
    }
    .user_info {
      margin-left: 1rem;
      .user_name {
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 2rem;
        color: #2e2e3a;
      }
      .stat {
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: #9a9aaf;
      }
    }
  }
}

.header {
  padding: 1.2rem 3rem;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  z-index: 1;
  position: relative;
  background: #ffffff;
  box-shadow: 0 0.4rem 2rem rgba(208, 208, 208, 0.3);
}

.logo {
  display: flex;
  align-items: center;
  .circle {
    width: 1.5rem;
    height: 1.5rem;
    background: #ff9900;
    border-radius: 50%;
    margin-right: 1rem;
  }
  .logo_title {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: #000;
  }
  .separator {
    width: 0.05rem;
    height: 1.8rem;
    margin: 0 1rem;
    background: #919191;
  }
  .gray {
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: #919191;
  }
}

.switcher {
  width: fit-content;
  > div {
    width: auto;
  }
}
.icon_container {
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 2rem;
  position: relative;
  top: 0.5rem;
  left: -1rem;
  transition: all 0.3s ease;
  &.open {
    left: 0;
  }
}

.icon {
  position: relative;
  top: 0;
  left: 0;
  width: 2.4rem;
  height: 2.4rem;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.75s ease-in-out;
  -moz-transition: 0.75s ease-in-out;
  -o-transition: 0.75s ease-in-out;
  transition: 0.75s ease-in-out;
  cursor: pointer;
  opacity: 0.9;
  &.open {
    // left: 3rem;

    span {
      &:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: -0.3rem;
        left: 0.7rem;
      }
      &:nth-child(2) {
        width: 0%;
        opacity: 0;
      }
      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 1.425rem;
        left: 0.7rem;
      }
    }
  }
  span {
    display: block;
    position: absolute;
    height: 0.15rem;
    width: 100%;
    background: #000000;
    border-radius: 1rem;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;

    &:nth-child(1) {
      top: 0px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
    &:nth-child(2) {
      top: 0.7rem;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
    &:nth-child(3) {
      top: 1.4rem;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
  }
  svg {
    width: 1.8rem;
    height: 1.8rem;

    path {
      stroke: #000000;
      fill: #000000;
    }
  }
}

@media (orientation: portrait), (max-width: 768px) {
  .icon_container {
    display: none;
  }
  .header {
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    .header_item {
      width: 4rem;
      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &.mirror {
        transform: scale(-1, 1);
      }
    }
  }
  .nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    .nav_item {
      color: #2e2e3a;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2rem;
      margin: 1.5rem 0;
      text-align: center;
      &.active {
        color: #0062ff;
      }
    }
  }
}
