@import url('./fonts/Inter/stylesheet.css');
@import url('./fonts/OpenSansRounded/stylesheet.css');
@import url('./fonts/Circe/stylesheet.css');

html {
  font-size: 62.5%;
}
html,
body {
  font-family: 'Inter';
  position: relative;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}
a {
  text-decoration: none;
}

#root {
  height: 100%;
  > div {
    height: 100%;
    > div {
      height: 100%;
    }
  }
}

@media (orientation: portrait), (max-width: 768px) {
  #root {
    background: #f5f3ef;
  }
}

// div {
// &::-webkit-scrollbar-track {
//   border-radius: 10px;
//   background-color: #31373e;
// }

// &::-webkit-scrollbar {
//   width: 5px;
//   background-color: #31373e;
// }

// &::-webkit-scrollbar-thumb {
//   border-radius: 10px;
//   background-color: #48505a;
// }
// }

@media (max-width: 1700px) {
  html,
  body {
    font-size: 62.5%;
  }
}
@media (max-width: 1300px) {
  html,
  body {
    font-size: 43.5%;
  }
}

@media (max-width: 768px) {
  html,
  body {
    // font-size: 62.5%;
    font-size: 43.5%;
    // &.mb-kassa {
    //   font-size: 47.5% !important;
    // }
  }
}

.ReactModalPortal {
  z-index: 999999;
  position: relative;
}

.ReactModalPortal .popup-content {
  padding: 0;
  border: none;
}

.ReactModalPortal .popup-content.full-open-content {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 93rem;
}

.ReactModal__Overlay {
  opacity: 0;
  // transition: opacity 300ms ease-in-out;
}

@media (orientation: portrait) {
  .ReactModal__Overlay {
    padding: 0 0 !important;
  }
}

.ReactModal__Body--open {
  overflow: hidden !important;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

* {
  box-sizing: initial;
  outline: none;
}
button:focus {
  outline: none;
}
button {
  cursor: pointer;
}

.circle_progress_mini {
  width: 3rem !important;
  height: 3rem;
}
.circle_progress_mini svg {
  width: 3rem !important;
  height: 3rem;
}
.circle_progress_feed_page {
  width: 8.5rem !important;
  height: 8.5rem;
}
.circle_progress_feed_page svg {
  width: 8.5rem !important;
  height: 8.5rem;
}
@media (min-width: 768px) {
  .circle_progress_feed_page {
    width: 17.5rem !important;
    height: 17.5rem;
  }
  .circle_progress_feed_page svg {
    width: 17.5rem !important;
    height: 17.5rem;
  }
}
.page_preloader {
  display: flex;
  flex: 1;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
}
.page_preloader svg {
  width: 16rem;
  height: 10rem;
}

.Toastify {
  position: relative;
  z-index: 9999999999;
}

.gallery-thumbs .swiper-slide-thumb-active .slide-content {
  border: 0.2rem solid #ff3e6c;
  border-radius: 0.5rem;
}

.swiper-button-disabled {
  opacity: 0;
}
.swiper-slide {
  position: relative;
}
.swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
  display: flex;
  align-items: center;
  justify-content: center;
  .swiper-pagination-bullet {
    background: #e4e4e4;
    width: 7px;
    height: 7px;
    opacity: 1;
    &.swiper-pagination-bullet-active {
      width: 10px;
      height: 10px;
    }
  }
}

.Toastify {
  position: relative;
  z-index: 9999999999;
}
// .Toastify__toast-container {
//   width: fit-content !important;
// }
// .Toastify__toast-container--top-center {
//   top: 50% !important;
//   left: 50% !important;
//   transform: translate(-50%, -50%) !important;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   z-index: 999999;
// }
// .Toastify__toast {
//   padding: 0;
//   box-shadow: none;
//   background-color: rgba(2, 2, 2, 0.79);
//   border-radius: 5rem;
//   height: auto;
//   min-height: auto;
//   width: fit-content;
//   .Toastify__toast-body {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     padding: 0;
//     margin: 0;
//     padding: 1rem 3rem 1rem 3rem;
//     > div {
//       font-weight: 500;
//       font-size: 2rem;
//       color: #ffffff;
//       line-height: 2.2rem;
//       font-weight: bold;
//       margin: 0;
//       white-space: nowrap;
//       font-family: 'Inter';
//     }
//   }
// }

.ReactCollapse--collapse {
  transition: height 500ms;
}

.ReactCollapse--content {
}

.calendar_container {
  font-size: 1.6rem;
  width: 22rem;

  .react-datepicker__month-container {
    width: 22rem;

    .react-datepicker__header {
      padding: 1rem 0;

      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker-year-header {
        font-size: 1.6rem;
        margin-bottom: 0.5rem;
      }

      .react-datepicker__day-names {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 0;
      }
    }
    .react-datepicker__month {
      .react-datepicker__week {
        display: flex;
        justify-content: space-evenly;
        .react-datepicker__day,
        .react-datepicker__month-text,
        .react-datepicker__quarter-text,
        .react-datepicker__year-text {
          width: 2.7rem;
          padding: 0.3rem;
        }
      }
    }
  }
}

.react-datepicker__input-container {
  width: 22rem;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__close-icon::after {
  font-size: 3rem;
  background-color: transparent;
  color: rgb(192, 189, 189);
  padding: 0;
}
