.page_container {
  flex: 1;
  background-color: #f7f7f7;
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4rem 9rem;

  .right {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: fit-content;
  }

  .notice {
    font-size: 1.6rem;
    line-height: 130%;
    color: #000;
  }

  .button {
    // width: 8rem;
    width: fit-content;
    height: 3.6rem;
    margin-left: 4rem;

    a {
      width: fit-content;
      padding: 0 1.5rem;
    }
  }
}

.form {
  padding: 5.5rem 7.5rem;
  background: #fff;
  border-radius: 1rem;
  width: fit-content;
  box-sizing: border-box;
  width: 49.5rem;
  margin: 3rem auto;

  .title {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 130%;
    text-align: center;
  }

  .notice {
    font-weight: 500;
    font-size: 2rem;
    line-height: 130%;
    text-align: center;
    margin-top: 2rem;
  }

  .fields {
    margin-top: 3rem;
  }

  .checkbox_container {
    align-items: flex-start;

    .checkbox {
      background-color: #eeeeee;
      width: 2.4rem;
      height: 2.4rem;
      margin-right: 1.2rem;
    }

    .checkbox_txt {
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 130%;
      color: #b0b0b0;
    }

    .active_checkbox {
      background-color: #ff9900;
      border-color: #ff9900;
    }
  }

  .big_btn {
    margin-top: 2rem;

    button {
      width: 100%;
      height: 6rem;
      font-size: 1.4rem;
      line-height: 130%;
      font-weight: 700;
    }
  }
}

.pass_reset_btn {
  color: #0062ff !important;
  width: 100%;
  padding: 1rem;
}
