.customSelect__control {
  font-size: 1.6rem;
  line-height: 2rem;
  border: none !important;
  box-shadow: none !important;
  border-radius: 1rem !important;
  outline: none;
  padding: 0 0 0 2rem;
  .customSelect__input-container {
    cursor: text;
  }
  .customSelect__indicator {
    cursor: pointer;
  }
}
.customSelect__menu {
  font-size: 1.6rem;
  line-height: 2rem;
  overflow: hidden;
  border-radius: 1rem !important;
  .customSelect__option {
    cursor: pointer;
  }
  .customSelect__menu-list {
    padding: 0;
  }
  .customSelect__option--is-focused {
    // background-color: #fbfbfb;
    background-color: #fff;
  }
  .customSelect__option--is-selected {
    background-color: #ff7a00;
    color: #fff !important;
  }

  .customSelect__option {
    color: #000;
  }
}
// .customSelect__single-value {
//   color: #fff;
// }
// .customSelect__input-container {
//   color: #fff;
// }
