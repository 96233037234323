.container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  position: relative;
  cursor: text;
  &.focus {
    .title.little_title {
      font-size: 1.2rem;
      top: 0.6rem;
    }
  }
  &.with_title {
    .input_container {
      padding-left: 0;
      input,
      textarea {
        padding: 2.6rem 2.5rem 1rem 2.5rem;
        font-size: 1.6rem;
        line-height: 1.9rem;
        background-color: #f8f8f8;
        border-color: #f8f8f8;
        font-weight: 700;
      }
    }
    .select_container {
      padding: 2.6rem 1rem 1rem 2.5rem;
      background-color: #f8f8f8;
    }
  }
}

.title {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  width: 20%;

  &.little_title {
    position: absolute;
    z-index: 1;
    top: 2rem;
    left: 2.5rem;
    // z-index: 3;
    font-size: 1.4rem;
    color: #000;
    transition: all 0.2s ease-in-out;
    width: fit-content;
    cursor: text;
  }
}
.input_container {
  flex: 1;
  padding-left: 1.5rem;
  max-width: 43.5rem;
  position: relative;
  .input {
    border: 0.1rem solid #f0f0f0;
    border-radius: 0.5rem;
    background-color: #fff;
    box-sizing: border-box;
    padding: 1.2rem 1.5rem;
    width: 100%;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-family: 'Inter';
    resize: none;

    &.error {
      border: 0.1rem solid #ff3e6c !important;
    }
  }
}
.select_container {
  border: 0.1rem solid #f0f0f0;
  border-radius: 0.5rem;
  background-color: #fff;
  box-sizing: border-box;
  padding: 0;
  width: 100%;
}
.dropdown_trigger {
  padding: 1rem;
  background: #eeeeee;
  width: 4.4rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}
.clear_indicator {
  padding: 1rem;
  background: #eeeeee;
  width: 4.4rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  svg {
    width: 1.4rem;
    height: 1.4rem;
  }
}
.upload_btn {
  background: #ffffff;
  border: 0.1rem solid #f0f0f0;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 12.5rem;
  box-sizing: border-box;
  color: #0290ea;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  cursor: pointer;
  svg {
    padding-right: 1rem;
  }
  span {
    text-align: center;
  }
}

.files {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 1rem;

  .preview {
    width: 8.5rem;
    height: 8.5rem;
    margin-right: 2rem;
    border: 0.1rem solid #f0f0f0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .txt {
      word-break: break-all;
      text-align: center;
      font-size: 1rem;
      line-height: 1.2rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .remove_btn {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 50%;
      border: 0.1rem solid #919191;
      background-color: #fff;
      overflow: hidden;
      top: -1rem;
      right: -1rem;
      cursor: pointer;

      svg {
        width: 1.2rem;
        height: 1.2rem;
        path {
          stroke: #919191;
        }
      }
    }
  }
}

.right_content {
  position: absolute;
  right: 0;
  top: 0;
  right: 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.switch {
  height: 2.9rem !important;
  width: 5.4rem !important;
  background-color: #eeeeee !important;
  box-shadow: none !important;
  border: 1px solid #eeeeee !important;
  // pointer-events: none !important;
  span {
    height: 2.3rem !important;
    width: 2.3rem !important;
    left: 0.2rem !important;
    top: 0.2rem !important;
    cursor: pointer !important;
    background-color: #ff9900 !important;
  }

  &.checked {
    span {
      left: 2.6rem !important;
      background-color: #24ca49 !important;
    }
  }
}
