.light {
  background: #ffffff;
}

.light_btn {
  svg {
    path {
      stroke: #000000;
    }
  }
}

.item_container {
  padding: 2rem;
  border-radius: 1rem;
  background: #3e4650;
  margin-bottom: 1.5rem;

  &.light {
    background: #f8f8f8;
    .info {
      .name {
        color: #000000;
      }
      .value {
        color: #000000;
      }
    }
  }
  .item_inner_container {
    display: flex;
  }
  .orange {
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.4rem;
    color: #ff9900;
  }
  .info {
    flex: 1;
    margin-left: 3rem;
    margin-right: 3rem;

    .name {
      font-weight: 600;
      font-size: 2rem;
      line-height: 2.4rem;
      color: #ffffff;
    }
    .value {
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: #fff;
    }
    .adds {
      margin-top: 1rem;
      .add_item {
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: #fff;
      }
    }
  }
  .discont {
    font-size: 2rem;
    line-height: 2.6rem;
    color: #f2994a;
    margin-bottom: 1rem;
  }
}

.btn_container {
  width: 100%;
  margin-top: auto;

  button {
    width: 100%;
    height: 8rem;
    font-size: 2.2rem;
    line-height: 2.6rem;
  }
}
