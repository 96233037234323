.table {
  .table_pagination {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    .pagintaion_info {
      display: flex;
      margin-right: 3rem;
      align-items: center;
      .pagination_text {
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: #000;
      }
      .input_page_container {
        margin: 0 1rem;
        width: 6rem;
        div {
          padding: 0;
          input {
            padding: 0.75rem;
            border-color: #dfdddd;
          }
        }
      }
      .btn_page {
        button {
          width: fit-content;
          padding: 0 1rem;
        }
      }
    }

    .pagination_btn_container {
      width: fit-content;
      margin: 0 1rem;
    }
  }
  .table_head {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    border-bottom: 0.2rem solid #e2e2e9;

    .head_item {
      color: #b0b0b0;
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.6rem;
      flex: 1;
      padding: 0 1rem;

      &.buttons_item > div {
        width: 21rem;
      }
    }
    .row_number {
      width: 3rem;
      color: #b0b0b0;
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.6rem;
      flex-shrink: 0;
    }
  }
  .total_row {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    background: #f8f8f8;
    .total_item {
      color: #000;
      font-style: normal;
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 1.8rem;
      flex: 1;
      padding: 0 1rem;
    }
    .row_number {
      width: 3rem;
      color: #000;
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.6rem;
      flex-shrink: 0;
    }
  }
  .row_number {
    // position: absolute;
    // left: 0;
    // top: 2.5rem;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.9rem;
    width: 3rem;
  }
  .table_row {
    padding: 2.5rem 0;
    display: flex;
    // align-items: center;
    position: relative;
    border-bottom: 0.2rem solid #e2e2e9;
    &:last-child {
      border-bottom: 0;
    }
    &:hover {
      background: #fff9ef;
    }
    .row_item {
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: #000000;
      flex: 1;
      padding: 0 1rem;
      word-break: break-all;
      p {
        margin: 0;
      }
    }
    .buttons_item {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      > div {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
        width: fit-content;
        width: 21rem;
        justify-content: flex-start;
        > div {
          width: fit-content;
        }
        a,
        button {
          width: auto;
          height: auto;
          margin: 0 1rem;
          &:hover {
            text-decoration: underline;
            color: #44444e;
          }
        }
      }
    }
  }
  .table_head .checkbox,
  .table_row .checkbox {
    width: 3rem;
    margin-right: 2rem;
    flex: 0;
  }
}
.empty_data {
  text-align: center;
  padding: 5rem;
  font-size: 2rem;
  transition: 0.3s ease-in-out;
}

.clickable {
  cursor: pointer;
}

.big_table {
  overflow: auto;
  .table_head {
    width: fit-content;
    min-width: 100%;
    .head_item {
      min-width: 20rem;
      flex-shrink: 0;
    }
  }
  .row_number {
    min-width: unset !important;
    flex-shrink: 0;
  }
  .table_row {
    width: fit-content;
    .row_item {
      min-width: 20rem;
      flex-shrink: 0;
    }
  }
}

@media (orientation: portrait), (max-width: 768px) {
  .table {
    overflow: auto;
    .table_head {
      width: fit-content;
      min-width: 100%;
      .head_item {
        min-width: 20rem;
        flex-shrink: 0;
      }
    }
    .row_number {
      min-width: unset !important;
      flex-shrink: 0;
    }
    .table_row {
      width: fit-content;
      .row_item {
        min-width: 20rem;
        flex-shrink: 0;
      }
    }
  }
}

.tooltip {
  background-color: #ffffff !important;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  max-width: 25rem;
  opacity: 1 !important;
  .tooltip_name {
    font-style: normal;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin-bottom: 0.4rem;

    color: #000000;
  }
  .tooltip_info {
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin-bottom: 0.4rem;
    color: #000000;
  }
}
