.page_layout {
  display: flex;
  flex: 1;
  height: 100%;
  position: relative;
}

.menu {
  width: 24rem;
  background-color: #fff;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease-in-out;

  &.collapse {
    width: 7.5rem;
  }
}
.content {
  background: #f5f3ef;
  flex: 1;
  overflow: auto;
  transform: translate3d(0, 0, 0);
  height: 100%;
}

@media (orientation: portrait), (max-width: 768px) {
  .menu {
    width: 100%;
    position: fixed;
    top: 6.4rem;
    left: -100%;
    transition: transform 0.3s ease;
    z-index: 1;
    height: 100vh;
    overflow: auto;
    &.showed {
      transform: translateX(100%);
    }
    &.header_menu {
      left: unset;
      right: -100%;
      &.showed {
        transform: translateX(-100%);
      }
    }
  }
}
