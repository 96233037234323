.page_container {
  flex: 1;
  background-color: #f7f7f7;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4rem 9rem;

  .right {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: fit-content;
  }
  .notice {
    font-size: 1.6rem;
    line-height: 130%;
    color: #000;
  }
  .button {
    // width: 8rem;
    width: fit-content;
    height: 3.6rem;
    margin-left: 4rem;
    a {
      width: fit-content;
      padding: 0 1.5rem;
    }
  }
}

.content {
  margin-top: 3rem;

  .notice {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 130%;
    color: #000;
    text-align: center;
    margin: 0 auto;
    max-width: 40rem;
  }

  .slide_content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .img_block {
      width: 37rem;
      height: 37rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .slide_text {
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 130%;
      max-width: 55.6rem;
      text-align: center;
      margin-top: 7rem;
    }
  }
  .pagination {
    top: 40rem;
    bottom: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .bullet {
      width: 1rem;
      height: 1rem;
      background: #b0b0b0;
      display: block;
      border-radius: 1rem;
      flex-shrink: 0;
      margin: 0 0.7rem;
    }
    .bullet_active {
      width: 1.5rem;
      height: 1.5rem;
      background: #ff7a00;
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;

  .footer_item {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 130%;
    color: #b0b0b0;
  }
  a {
    &:hover {
      color: #ff7a00;
    }
  }
  .separator {
    height: 2.2rem;
    width: 0.1rem;
    background: #b0b0b0;
    margin: 0 1rem;
  }
}
