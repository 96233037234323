.title {
  text-align: center;
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 130%;
  color: #000;
}
.search_container {
  padding: 0;
  margin-top: 2rem;
  > div {
    padding: 0;
    margin: 0 auto;
  }
}

.row {
  padding: 1.5rem 0;
  border-bottom: 0.1rem solid #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.2rem;
  .row_name {
    color: #0290ea;
  }
  .row_value {
    color: #000;
  }
}
