.container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
}

.filter_item {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #0290ea;
  padding: 1.5rem;
  cursor: pointer;
  margin-right: 1rem;
  position: relative;
  z-index: 2;

  .delete_icon {
    display: none;
    justify-content: center;
    align-items: center;
    svg {
      width: 1.6rem;
      height: 1.4rem;
      margin-left: 0.5rem;
    }
  }

  &.selected {
    background: #e1e9ef;
    border-radius: 1rem;
  }

  &:hover {
    background: #e1e9ef;
    border-radius: 1rem;
    .delete_icon {
      display: flex;
    }
  }
  svg {
    width: 1.4rem;
    height: 1.4rem;
    margin-right: 0.6rem;
    position: relative;
    top: -0.2rem;
    path {
      stroke: #0290ea;
    }
  }
}
.back_btn {
  width: fit-content;
  margin-bottom: 1rem;
  button {
    width: fit-content;
    padding: 0 1rem;
  }
}
.dropdown_container {
  position: relative;
}
.dropdown_menu {
  position: absolute;
  display: none;
  top: 6rem;
  left: 0;
  right: auto;
  background: #ffffff;
  box-shadow: 0px 4px 40px #f0f0f0;
  border-radius: 10px;
  padding: 1rem 0;
  width: 24rem;
  max-height: 25rem;
  overflow: auto;
  z-index: 3;
  &.from_right {
    right: 0;
    left: auto;
  }

  &.long {
    width: fit-content;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #eaeaea;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: #eaeaea;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #bebebe;
  }

  .dropdown_variety {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 1rem 2.5rem;
    font-weight: normal;
    font-size: 1.5rem;
    font-weight: 500;
    color: #000000;
    transition: all 0.3s ease;
    &.filter_variety {
      font-weight: normal;
    }
    &.selected {
      background: #fff9ef;
      color: #000;
    }
    &:hover {
      background: #fff9ef;
      color: #000;
    }
  }

  .filter_variety {
    cursor: pointer;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.6rem;
    padding: 1rem 1.5rem;
    transition: all 0.3s ease;
    background-repeat: no-repeat;
    background-position: right center;
    &:hover {
      color: #ff9900;
    }
    &.selected {
      color: #ff9900;
      background-image: url('/images/icons/correct_svg.svg');
      background-repeat: no-repeat;
      background-position: right center;
    }
  }

  &.opened {
    display: block;
    &.long {
      display: flex;
    }
  }
}
.buttons {
  display: flex;
}
.submit_button {
  margin-top: 2rem;
}
.filters {
  width: 24rem;
}
.filter_content {
  flex: 1;
  padding: 1rem 2rem;
  width: 25rem;
  border-left: 0.1rem solid #eaeaea;
  .title {
    font-weight: 500;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}
.submitted_filter_content {
  padding: 1rem 2rem;
  width: fit-content;
  .title {
    font-weight: 500;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .dropdown_menu {
    position: fixed;
    left: 4rem !important;
    top: 18rem;
    width: calc(100% - 8rem) !important;
  }
  .filter_item {
    margin-bottom: 1rem;
  }
}
