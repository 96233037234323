.app_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.page_content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  height: calc(100% - 7rem);
}

.tech_time {
  width: calc(100% - 2rem);
  padding: 1rem;
  background-color: red;
  color: white;
  font-size: 3rem;
  text-align: center;
}

@media screen and (min-width: 769px) {
  * {
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #eaeaea;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #eaeaea;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #bebebe;
    }
  }
}
