.container {
  position: relative;
  background-color: #f8f8f8;
  border-radius: 1.5rem;
  .icon {
    padding-left: 1rem;
    position: absolute;
    width: 3rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input {
    display: block;
    padding: 1.5rem;
    padding-left: 4.5rem;
    flex: 1;
    background: transparent;
    border: none;
    width: 100%;
    box-sizing: border-box;
    font-size: 1.4rem;
    font-family: 'Circe';
    &::placeholder {
      color: #4f4f4f;
    }
  }
}
