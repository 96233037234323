.title {
  text-align: center;
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 130%;
  color: #fff;
}
.subtitle {
  text-align: center;
  //   font-weight: 500;
  font-size: 1.8rem;
  line-height: 130%;
  color: #fff;
  margin-top: 2rem;
}

.inner_container {
  width: fit-content;
  margin: 0 auto;
}
.btn {
  margin-top: 3rem;
  button {
    width: 26rem;
    height: 6rem;
  }
}
