.item {
  min-width: 12rem;
  height: 4.5rem;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #525d69;
  cursor: pointer;
  background-color: transparent;
  transition: 0.3s ease-in-out;
  position: relative;

  &.light {
    background: #f8f8f8;
    border-color: #d7d7d7;
    .title {
      color: #000000;
    }
    .price {
      color: #ff9900;
    }
  }

  &.active_green {
    border: 1px solid #24ca49;
  }
  &.active_orange {
    border: 1px solid #ff7a00;
  }
  &.item_center {
    flex-direction: column;
  }

  &.item_option {
    align-items: flex-start;
    padding: 2rem 6rem 2rem 2rem;

    .float_handles {
      position: absolute;
      top: -1px;
      right: -1px;
      width: 4rem;
      height: 100%;
      border-top: 1px solid #24ca49;
      border-bottom: 1px solid #ff3a44;
      border-radius: 0px 5px 5px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .plus,
      .minus {
        height: 51%;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
          position: relative;
        }
      }

      .plus {
        background: #24ca49;
        border: 1px solid #24ca49;
        border-radius: 0px 5px 0px 0;

        .icon {
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 1.5rem;
            height: 2px;
            background-color: #ffffff;
          }
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 2px;
            height: 1.5rem;
            background-color: #ffffff;
          }
        }
      }
      .minus {
        border: 1px solid #ff3a44;
        background: #ff3a44;
        border-radius: 0px 0px 5px 0;
        .icon {
          &::after {
            content: '';
            position: absolute;
            top: 0%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 1.5rem;
            height: 2px;
            background-color: #ffffff;
          }
        }
      }
    }
  }

  &.green {
    background: #24ca49;
  }
  &.orange {
    background: #ff7a00;
  }
  &.material {
    background: #242b33;
  }
  &.light_material {
    background: #f8f8f8;
    border-color: #d7d7d7;
  }

  &.light_active {
    .title {
      color: #ffffff !important;
    }
    .price {
      color: #fcb370;
    }
  }

  &.active_green {
    background: #24ca49 !important;
  }
  &.active_orange {
    background: #ff7a00 !important;
  }
  &.active_material {
    background: #242b33 !important;
  }

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    line-height: 100%;
    color: #ffffff;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    // width: calc(100% - 2rem);
    text-align: center;

    &.title_center {
      font-size: 2rem;
    }
  }
  .price {
    font-style: normal;
    font-weight: 300;
    font-size: 1.5rem;
    line-height: 100%;
    color: rgba(255, 255, 255, 0.63);
    margin-top: 0.8rem;
  }
  .in_stock_badge {
    position: absolute;
    width: 1rem;
    height: 1rem;
    background: #24ca49;
    border-radius: 50%;
    top: 0.3rem;
    right: 0.3rem;
  }
}

@media (orientation: portrait), (max-width: 768px) {
  .item {
    width: 12rem;
    height: 5rem;
    .title {
      font-size: 1.4rem;
      line-height: 100%;
      &.title_center {
        font-size: 1.4rem;
      }
    }
    .price {
      font-size: 1.2rem;
      margin-top: 0.8rem;
    }
  }
}
@media screen and (max-width: 440px) {
  .item {
    width: 48%;
    height: 7rem;
    padding: 0;
    box-sizing: border-box;
    // height: 5rem;
    // .title {
    //   font-size: 1.4rem;
    //   line-height: 100%;
    //   &.title_center {
    //     font-size: 1.4rem;
    //   }
    // }
    // .price {
    //   font-size: 1.2rem;
    //   margin-top: 0.8rem;
    // }
  }
}
